import React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import "./navbar.scss";

import DarkModeToggle from "../theme/DarkModeToggle";

const COLORS = {
    primaryDark: "rgba(8, 7, 5, 1)",
    primaryLight: "rgba(255, 1, 110, 1)",
};

const MenuLabel = styled.label`
    background-color: ${COLORS.primaryLight};
    position: fixed;
    top: 2rem;
    right: 2.7rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    z-index: 1000;
    text-align: center;
  `;

const NavBackground = styled.div`
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background: rgba(255, 1, 110, 1);
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 600;
    transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
    transition: transform 0.8s;
  `;

const Icon = styled.span`
    position: relative;
    background-color: ${(props) => (props.clicked ? "transparent" : "black")};
    width: 1rem;
    height: 2px;
    display: inline-block;
    margin-top: 1.5rem;
    transition: all 0.3s;
  
    &::before,
    &::after {
      content: "";
      background-color: black;
      width: 1rem;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
  
    &::before {
      top: ${(props) => (props.clicked ? "0" : "-0.5rem")};
      transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }
  
    &::after {
      top: ${(props) => (props.clicked ? "0" : "0.5rem")};
      transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
    }
  
    ${MenuLabel}:hover &::before {
      top: ${(props) => (props.clicked ? "0" : "-.75rem")};
    }
    ${MenuLabel}:hover &::after {
      top: ${(props) => (props.clicked ? "0" : ".75rem")};
    }
  `;

const Navigation = styled.nav`
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 600;
    width: ${(props) => (props.clicked ? "100%" : "0")};
    opacity: ${(props) => (props.clicked ? "1" : "0")};
    transition: width 0.8s, opacity 0.8s;
  `;

const List = styled.ul`
    position: absolute;
    list-style: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  `;


function NavBar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>

            <nav id="full-nav" className="navbar ">

                <DarkModeToggle />

                <div id="full-width-nav">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex flex-wrap space-x-10">
                            <li className="nav-item hover:text-[#ff006e] transform hover:scale-125 duration-300 ease-in-out">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item hover:text-[#ff006e] transform hover:scale-125 duration-300 ease-in-out">
                                <a className="nav-link" href="/about">About</a>
                            </li>
                            <li className="nav-item hover:text-[#ff006e] transform hover:scale-125 duration-300 ease-in-out">
                                <a className="nav-link" href="/blog">Blog</a>
                            </li>
                            <li className="nav-item hover:text-[#ff006e] transform hover:scale-125 duration-300 ease-in-out">
                                <a className="nav-link" href="/projects">Projects</a>
                            </li>
                            <li className="nav-item hover:text-[#ff006e] transform hover:scale-125 duration-300 ease-in-out">
                                <a className="nav-link" href="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="responsive-nav">

                    <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                        <Icon clicked={click}>&nbsp;</Icon>
                    </MenuLabel>
                    <NavBackground clicked={click}>&nbsp;</NavBackground>

                    <Navigation clicked={click}>
                        <List>
                            <li className="text-pink-100 no-underline hover:text-stone-900 transform hover:scale-125 duration-300 ease-in-out"  >
                                <Link to="/">Home</Link>
                            </li>
                            <li className="text-pink-100 no-underline hover:text-stone-900 transform hover:scale-125 duration-300 ease-in-out"  >
                                <Link to="/about">About</Link>
                            </li>
                            <li className="text-pink-100 no-underline hover:text-stone-900 transform hover:scale-125 duration-300 ease-in-out"  >
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li className="text-pink-100 no-underline hover:text-stone-900 transform hover:scale-125 duration-300 ease-in-out"  >
                                <Link to="/projects">Projects</Link>
                            </li>
                            <li className="text-pink-100 no-underline hover:text-stone-900 transform hover:scale-125 duration-300 ease-in-out"  >
                                <Link to="/contact">Contact</Link>
                            </li>

                            <span className="inline-flex space-between pt-5">

                                <a data-tooltip="Connect with me on LinkedIn!" className="inline-block text-pink-100 no-underline hover:text-stone-900 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out" href="https://linkedin.com/in/kendrawing" rel="noreferrer" target="_blank">
                                    <svg className="fill-current h-10" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                                    </svg>
                                </a>

                                <a className="inline-block text-pink-100  no-underline hover:text-stone-900 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out" href="https://github.com/kwing25" rel="noreferrer" target="_blank">
                                    <svg className="fill-current h-10" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                                    </svg>
                                </a>

                                <a className="inline-block text-pink-100 no-underline hover:text-stone-900 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out" href="https://dev.to/kwing25" rel="noreferrer" target="_blank">
                                    <svg className="fill-current h-10" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.42 10.05c-.18-.16-.46-.23-.84-.23H6l.02 2.44.04 2.45.56-.02c.41 0 .63-.07.83-.26.24-.24.26-.36.26-2.2 0-1.91-.02-1.96-.29-2.18zM0 4.94v14.12h24V4.94H0zM8.56 15.3c-.44.58-1.06.77-2.53.77H4.71V8.53h1.4c1.67 0 2.16.18 2.6.9.27.43.29.6.32 2.57.05 2.23-.02 2.73-.47 3.3zm5.09-5.47h-2.47v1.77h1.52v1.28l-.72.04-.75.03v1.77l1.22.03 1.2.04v1.28h-1.6c-1.53 0-1.6-.01-1.87-.3l-.3-.28v-3.16c0-3.02.01-3.18.25-3.48.23-.31.25-.31 1.88-.31h1.64v1.3zm4.68 5.45c-.17.43-.64.79-1 .79-.18 0-.45-.15-.67-.39-.32-.32-.45-.63-.82-2.08l-.9-3.39-.45-1.67h.76c.4 0 .75.02.75.05 0 .06 1.16 4.54 1.26 4.83.04.15.32-.7.73-2.3l.66-2.52.74-.04c.4-.02.73 0 .73.04 0 .14-1.67 6.38-1.8 6.68z" />
                                    </svg>
                                </a>

                                <a className="inline-block text-pink-100  no-underline hover:text-stone-900 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out" href="https://codepen.io/kwing25" rel="noreferrer" target="_blank">
                                    <svg className="fill-current h-10" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.144 13.067v-2.134L16.55 12zm1.276 1.194a.628.628 0 01-.006.083l-.005.028-.011.053-.01.031c-.005.016-.01.031-.017.047l-.014.03a.78.78 0 01-.021.043l-.019.03a.57.57 0 01-.08.1l-.026.025a.602.602 0 01-.036.03l-.029.022-.01.008-6.782 4.522a.637.637 0 01-.708 0L4.864 14.79l-.01-.008a.599.599 0 01-.065-.052l-.026-.025-.032-.034-.021-.028a.588.588 0 01-.067-.11l-.014-.031a.644.644 0 01-.017-.047l-.01-.03c-.004-.018-.008-.036-.01-.054l-.006-.028a.628.628 0 01-.006-.083V9.739c0-.028.002-.055.006-.083l.005-.027.011-.054.01-.03a.574.574 0 01.12-.217l.031-.034.026-.025a.62.62 0 01.065-.052l.01-.008 6.782-4.521a.638.638 0 01.708 0l6.782 4.521.01.008.03.022.035.03c.01.008.017.016.026.025a.545.545 0 01.08.1l.019.03a.633.633 0 01.021.043l.014.03c.007.016.012.032.017.047l.01.031c.004.018.008.036.01.054l.006.027a.619.619 0 01.006.083zM12 0C5.373 0 0 5.372 0 12 0 18.627 5.373 24 12 24c6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12m0 10.492L9.745 12 12 13.51 14.255 12zm.638 4.124v2.975l4.996-3.33-2.232-1.493zm-6.272-.356l4.996 3.33v-2.974l-2.764-1.849zm11.268-4.52l-4.996-3.33v2.974l2.764 1.85zm-6.272-.356V6.41L6.366 9.74l2.232 1.493zm-5.506 1.549v2.134L7.45 12Z" />
                                    </svg>
                                </a>

                                <a className="inline-block text-pink-100  no-underline hover:text-stone-900 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out" href="https://linktr.ee/kwingwebdev" rel="noreferrer" target="_blank">
                                    <svg className="fill-current h-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M7.953 15.066c-.08.163-.08.324-.08.486.08.517.528.897 1.052.89h1.294v4.776c0 .486-.404.89-.89.89H6.577a.898.898 0 01-.889-.891v-4.774H.992c-.728 0-1.214-.729-.89-1.377l6.96-12.627a1.065 1.065 0 011.863 0l2.913 5.585-3.885 7.042zm15.945 0l-6.96-12.627a1.065 1.065 0 00-1.862 0l-2.995 5.586 3.885 7.04c.081.164.081.326.081.487-.08.517-.529.897-1.052.89h-1.296v4.776c.005.49.4.887.89.89h2.914a.9.9 0 00.892-.89v-4.775h4.612c.73 0 1.214-.729.89-1.377z"></path>
                                    </svg>
                                </a>

                            </span>
                        </List>
                    </Navigation>

                </div>
            </nav>
        </>
    );
}

export default NavBar;